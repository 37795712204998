<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <TextInput
                  v-model="form.title"
                  type="text"
                  :label="$t('Title')"
                  :tooltipMessage="$t('Title')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <TextInput
                v-model="form.scope"
                type="text"
                :label="$t('Scope')"
                :tooltipMessage="$t('Scope')"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <TextInput
                v-model="form.value"
                type="number"
                :label="$t('Value')"
                :tooltipMessage="$t('Value')"
              />
            </div>
            <div class="col-md-6">
              <label class="form-label" for="needs_permission">{{
                $t("Needs Permission")
              }}</label>
              <MultiSelectInput
                v-model="form.needs_permission"
                :options="permissions"
                placeholder="Select Option"
                label="title"
                trackBy="id"
                :key="form.needs_permission"
                moduleName="permissions"
                :searchParamName="`search_string`"
                :multiple="false"
              >
              </MultiSelectInput>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="cars"> {{ $t("Company") }} </label>
              <MultiSelectInput
                v-model="form.company"
                :options="customers"
                label="companyName"
                trackBy="id"
                :key="form.company"
                moduleName="customers"
                :multiple="false"
                ><template #option="{ props }">
                  <div>
                    <p>
                      {{ props.option.companyNumber }} -
                      {{ props.option.companyName }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="groupings">{{
                $t("Groups")
              }}</label>
              <multi-select
                v-model="form.grouping"
                :options="groupings"
                :multiple="false"
                label="grouping"
                trackBy="grouping"
                selectLabel=""
                deselectLabel=""
                open-direction="bottom"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label class="form-label" for="cars">{{
                $t("Can Be Assigned")
              }}</label>
              <input
                id="can_be_assigned"
                class="form-check-input-custom ml-2"
                type="checkbox"
                v-model="form.can_be_assigned"
                name="can_be_assigned"
              />
            </div>
            <div class="col-md-6">
              <label class="form-label" for="cars">{{ $t("Active") }}</label>

              <input
                id="active"
                class="form-check-input-custom ml-2"
                v-model="form.active"
                type="checkbox"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label class="form-label" for="cars">{{
                $t("Available For All Tenants")
              }}</label>
              <input
                id="is_all_tenants"
                class="form-check-input-custom ml-2"
                type="checkbox"
                v-model="form.is_all_tenants"
                name="is_all_tenants"
              />
            </div>
            <div class="col-md-6">
              <label class="form-label" for="cars">{{
                $t("Available For All Companies")
              }}</label>

              <input
                id="is_all_companies"
                class="form-check-input-custom ml-2"
                v-model="form.is_all_companies"
                type="checkbox"
                name="is_all_companies"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label"> {{ $t("Description") }} </label>
                <textarea
                  id="description"
                  rows="5"
                  class="form-control"
                  name="description"
                  v-model="form.description"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button
                v-if="!group && !form.grouping"
                @click="addGroup"
                class="btn btn-primary d-block my-3"
              >
                + {{ $t("Add Group") }}
              </button>
              <Group
                v-if="group && !form.grouping"
                @deleteParentGroup="group = null"
                :isOriginal="true"
                @group="group = $event"
                :triggered="triggered"
                :group="group"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="update"
          class="d-flex align-items-center gap-1"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Permission") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import Group from "@/views/permissions/Group.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    Group,
    MultiSelectInput,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("permissions", ["permissions", "groupings"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Permissions"),
          to: "/permissions",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return !this.form.title;
    },
  },
  data() {
    return {
      options: [],
      form: {
        title: "",
        needs_permission: null,
        can_be_assigned: false,
        active: false,
        scope: "",
        value: "",
        grouping: "",
        description: "",
        company: {},
        is_all_tenants: false,
        is_all_companies: false,
      },
      triggered: false,
      group: null,
    };
  },

  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      if (!this.permissions?.length)
        await this.$store.dispatch("permissions/list", {
          limit_start: 0,
          limit_count: 25,
        });
      if (!this.groupings?.length)
        await this.$store.dispatch("permissions/listGroupings", {
          limit_start: 0,
          limit_count: 100,
        });
      await this.$store
        .dispatch("permissions/show", this.$route.params.id)
        .then(async (res) => {
          if (res.message_type != "error") {
            var response = res?.data;
            console.log("res", response);
            this.form = {
              title: response?.title ?? "",
              needs_permission: {},
              company: {},
              // needs_permission:
              //   this.permissions.find(
              //     (permission) =>
              //       permission.id == (+response?.needs_permission ?? "")
              //   ) ?? null,
              can_be_assigned: (response?.can_be_assigned ?? "") == 1,
              active: (response?.active ?? "") == 1,
              is_all_tenants: (response?.is_all_tenants ?? "") == 1,
              is_all_companies: (response?.is_all_companies ?? "") == 1,
              scope: response?.scope,
              value: response?.value,
              description: response?.description,
              grouping: response?.grouping,
            };
            if (response?.grouping)
              this.form.grouping = {
                grouping: response?.grouping,
              };
            if (response.needs_permission) {
              const data = await this.$store.dispatch(
                "permissions/show",
                response.needs_permission
              );
              this.form.needs_permission = data.data;
              let permissionData = [...this.customers];
              const exists = permissionData.some((c) => c.id === data.data.id);
              if (!exists) {
                permissionData.push({ ...data.data });
                this.$store.commit("permissions/permissions", permissionData);
              }
            }
            if (response.company_id) {
              const company = await this.$store.dispatch(
                "customers/show",
                response.company_id
              );
              this.form.company = company.data.modelData;
              let customersData = [...this.customers];
              const exists = customersData.some(
                (c) => c.id === company.data.modelData.id
              );
              if (!exists) {
                customersData.push({ ...company.data.modelData });
                this.$store.commit("customers/customers", customersData);
              }
            }
          }
        })
        .finally(() => {
          this.shouldShow = true;
        });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },

  methods: {
    /**
     * adds a group
     */
    addGroup() {
      this.group = {
        name: "",
        child: null,
      };
    },
    /**
     * reccursively checks if a group has an empty display name or value if yes then return true
     * @param {group} the group that we want to check error for
     * @returns boolean
     */
    checkForGroupError(group) {
      if (!group) return false;
      let hasError = false;
      if (!group.name) {
        hasError = true;
      } else {
        if (group.child) {
          hasError = this.checkForGroupError(group.child);
        }
      }
      return hasError;
    },
    /**
     * makes a group path string and returns it
     * this method is called recursively since we can have child and sub childs and so on
     * we traverse through all the sub groups to update a group path
     * @param {group} group object that has a 'name' and 'child' in it
     */
    getGroupPath(group) {
      if (!group) return "";
      let groupPath = "";
      groupPath += group.name;
      if (group.child) {
        groupPath += "/" + this.getGroupPath(group.child);
      }
      return groupPath;
    },
    async update() {
      this.triggered = true;
      if (!this.checkForGroupError(this.group)) {
        try {
          this.$refs.simpleRules.validate().then(async (success) => {
            if (success) {
              let response = await this.$store.dispatch("permissions/update", {
                id: this.$route.params.id,
                ...this.form,
                needs_permission: this.form.needs_permission?.id ?? null,
                can_be_assigned: this.form.can_be_assigned === true ? 1 : 0,
                active: this.form.active === true ? 1 : 0,
                is_all_tenants: this.form.is_all_tenants === true ? 1 : 0,
                is_all_companies: this.form.is_all_companies === true ? 1 : 0,
                grouping:
                  this.form.grouping?.grouping ?? this.getGroupPath(this.group),
              });
              if (response?.message_type !== "error") {
                await this.$router.push("/permissions");
              }
            }
          });
        } catch (e) {}
      }
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
